import { MollerFont } from '@moller/design-system/fonts';
import {
    GlobalStyles,
    mollerBilTheme,
    ThemeProvider,
} from '@moller/design-system';
import ErrorMessage from 'components/ErrorMessage';
import headers from 'config/headers';
import FormContainer from 'containers/FormContainer';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import IFormSchema from 'types/IFormSchemaJSON';
import { initDatadog } from 'utils/datadog';
import envHandler from 'utils/determineEnvironment';
import fetchForm from 'utils/fetchForm';
import '../index.css';
import './assets/fonts/audi/AudiType-Normal.woff';
import { sendIFrameEvent } from './utils/iframeEvents';
import Feedback from './components/Feedback';

const getTargetOriginForForm = (formName: string) => {
    // Only allow iframe events for cupraofficial.no
    // This should probably be configurable in sanity in the future
    if (formName == 'cupratavascan') {
        return 'https://www.cupraofficial.no';
    }
};
export const Form = () => {
    const [searchParams] = useSearchParams();
    const formName = searchParams.get('skjema');
    const isAudi = searchParams.get('b') === 'audi';
    const isSkoda = formName?.includes('skoda');
    const isPreview = searchParams.get('preview') === 'true';
    const formEventTargetOrigin = getTargetOriginForForm(formName);

    const [formSchemaJSON, setFormSchemaJSON] = useState<IFormSchema>();
    const [error, setError] = useState(false);
    const [completed, setCompleted] = useState(false);
    const [emailReceiptSuccess, setEmailReceiptSuccess] = useState(false);

    useEffect(() => {
        initDatadog();
    }, []);

    // Fetch form on initial render
    useEffect(() => {
        if (!formName) {
            // If no form name is provided, show error message instead of just a blank page
            setError(true);
            return;
        }

        fetchForm(headers, setFormSchemaJSON, setError, formName, isPreview);
    }, [formName, isPreview]);

    useEffect(() => {
        console.log('Using environment:', envHandler());
        if (isPreview) {
            console.log('Preview mode enabled – form will not be submitted');
        }
    }, [isPreview]);

    const onFormLoaded = () => {
        const titleElement = document.getElementById('head-title-tag');
        titleElement.innerHTML = formSchemaJSON?.title;
        sendIFrameEvent({ type: 'FORM_LOADED' }, formEventTargetOrigin);
    };

    const onFormSubmitted = () => {
        sendIFrameEvent({ type: 'FORM_SUBMITTED' }, formEventTargetOrigin);
    };

    const onFormError = () => {
        sendIFrameEvent({ type: 'FORM_ERROR' }, formEventTargetOrigin);
    };

    useEffect(() => {
        if (formSchemaJSON?.title) onFormLoaded();
    }, [formEventTargetOrigin, formSchemaJSON?.title]);

    useEffect(() => {
        if (error) onFormError();
    }, [error, formEventTargetOrigin]);

    useEffect(() => {
        if (completed) onFormSubmitted();
    }, [completed, formEventTargetOrigin]);

    const brandThemeClassName = isAudi
        ? 'audi-theme'
        : isSkoda
        ? 'skoda-theme'
        : '';

    return (
        <ThemeProvider theme={mollerBilTheme}>
            <GlobalStyles />
            <MollerFont />
            {error && <ErrorMessage />}
            {searchParams && formSchemaJSON && !error && (
                <div className={brandThemeClassName}>
                    {completed ? (
                        <Feedback emailReceiptSuccess={emailReceiptSuccess} />
                    ) : (
                        <FormContainer
                            formName={formName}
                            additionalData={searchParams.get('additionalData')}
                            formSchemaJSON={formSchemaJSON}
                            isPreview={isPreview}
                            setCompleted={setCompleted}
                            setEmailReceiptSuccess={setEmailReceiptSuccess}
                        />
                    )}
                </div>
            )}
        </ThemeProvider>
    );
};
