import FormContent from 'components/FormContent';
import React, { useMemo } from 'react';
import { FormState } from 'types/FormState';
import IFormInput from 'types/IFormInput';
import IFormSchema, { FormComponent } from 'types/IFormSchemaJSON';
import setValidatorFromFormSpec from 'utils/setValidatorFromFormSpec';
import {
    noValidation,
    ValidatorMap,
} from '@moller/design-system/utilities/validation';

function getInitialStateFromComponents(components: FormComponent[]) {
    return Object.fromEntries(
        components
            // Filter out submit button which is not wanted in initialState object
            .filter(function (component) {
                return component.key !== 'submit';
            })
            // Add component key (field type) with empty string to initialState object
            .map((component) => {
                const fieldKey = component.key;

                return [fieldKey, ''] as const;
            })
    );
}

function getValidatorsFromComponents(components: FormComponent[]) {
    return Object.fromEntries(
        components
            .filter(function (component) {
                return component.key !== 'submit';
            })
            .map((component) => {
                const fieldKey = component.key;

                return [fieldKey, setValidatorFromFormSpec(component)];
            })
    );
}

interface IFormContainer {
    formName: string;
    additionalData: string | undefined;
    formSchemaJSON: IFormSchema;
    isPreview?: boolean;
    setCompleted: React.Dispatch<React.SetStateAction<boolean>>;
    setEmailReceiptSuccess: React.Dispatch<React.SetStateAction<boolean>>;
}

const FormContainer: React.FC<IFormContainer> = ({
    formName,
    additionalData,
    formSchemaJSON,
    isPreview: boolean,
    setCompleted,
    setEmailReceiptSuccess,
}) => {
    const initialState: FormState = useMemo(
        () => ({
            ...getInitialStateFromComponents(formSchemaJSON.components),
            additionalData, // Used for additional data from query params, and not user input
        }),
        [formSchemaJSON, additionalData]
    );
    const validator: ValidatorMap<IFormInput> = useMemo(
        () => ({
            ...getValidatorsFromComponents(formSchemaJSON.components),
            additionalData: noValidation(),
        }),
        [formSchemaJSON]
    );

    return (
        initialState &&
        validator && (
            <FormContent
                initialState={initialState}
                validator={validator}
                formName={formName}
                formSchemaJSON={formSchemaJSON}
                setCompleted={setCompleted}
                setEmailReceiptSuccess={setEmailReceiptSuccess}
                isPreview={boolean}
            />
        )
    );
};

export default FormContainer;
