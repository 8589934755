import { SUBMIT_FORM_URL } from 'constants/backend';
import React from 'react';

// Define the response type from the API
export interface FormSubmissionResponse {
    message?: string;
    response?: {
        emailReceipt: boolean;
    };
}

const submitForm = (
    data: BodyInit,
    headers: Headers,
    formName: string,
    code: string,
    setRegNrError: React.Dispatch<React.SetStateAction<boolean>>,
    setCompleted: React.Dispatch<React.SetStateAction<boolean>>,
    setOTPValid: React.Dispatch<React.SetStateAction<boolean>>,
    setSubmitError: React.Dispatch<React.SetStateAction<boolean>>,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setEmailReceiptSuccess: React.Dispatch<React.SetStateAction<boolean>>
) => {
    const formNameSearchParam = new URLSearchParams({
        formName: formName,
    }).toString();

    const codeSearchParam = new URLSearchParams({
        code: code,
    }).toString();

    const url = `${SUBMIT_FORM_URL}${formNameSearchParam}&${codeSearchParam}`;

    fetch(`${url}`, {
        method: 'POST',
        headers: headers,
        body: data,
        redirect: 'follow',
    })
        .then((response: Response) => {
            setIsLoading(false);
            if (response.ok) {
                setCompleted(true);
            } else {
                setSubmitError(true);
            }
            return response.json();
        })
        .then((content: FormSubmissionResponse) => {
            if (!content.response) return;
            setEmailReceiptSuccess(content.response.emailReceipt);
        })
        .catch((error: unknown) => {
            const errorMessage =
                error instanceof Error ? error.toString() : String(error);

            setIsLoading(false);
            if (errorMessage.includes('code')) {
                setOTPValid(false);
            } else if (errorMessage.includes('carModel')) {
                setRegNrError(true);
            } else {
                setSubmitError(true);
            }
            throw error;
        });
};

export default submitForm;
